import React from "react";
import * as Router from "react-router-dom";
import {Route} from "react-router-dom";
import ErrorView from "UI/Base/Error/ErrorView";
import HomepageView from "UI/Base/Homepage/HomepageView";
import Props from "UI/Common/Props";

const BaseView: React.FunctionComponent<Props> = () => {
	return (
		<>
			<Router.Routes>
				<Route path="/" element={<HomepageView/>}/>
				<Route path="*" element={<ErrorView errorCode={404}/>}/>
			</Router.Routes>
		</>
	);
};

export default BaseView;