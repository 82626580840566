export interface Config {
	"app": {
		"name": string,
		"owner": {
			"fullName": string,
			"companyName": string,
			"ico": number,
			"dic": number,
			"email": string,
			"tel": string,
			"address": {
				"street": string,
				"city": string,
				"zip": string,
				"country": string
			}
		}
	},
	"api": {
		"url": string
	},
	"google": {
		"reCAPTCHA": string
	}
}

export const defaultConfig: Config = {
	"app": {
		"name": "TD Elektro",
		"owner": {
			"fullName": "Ing. Tomáš Domanický",
			"companyName": "TD Elektro",
			"ico": 53289595,
			"dic": 1077678371,
			"email": "info@tdelektro.sk",
			"tel": "+421 904 473 579",
			"address": {
				"street": "Stránské 77",
				"city": "Stránské",
				"zip": "013 13",
				"country": "SVK"
			}
		}
	},
	"api": {
		"url": "https://localhost/api"
	},
	"google": {
		"reCAPTCHA": "#"
	}
};

export const configUrl = "/config.json";