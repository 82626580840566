import ConfigContext from "Config/ConfigContext";
import React from "react";
import {Container, Navbar} from "react-bootstrap";
import ContactControl from "UI/Base/Homepage/Contact/ContactControl";
import "UI/Base/Homepage/HomepageView.scss"
import ServicesControl from "UI/Base/Homepage/Services/ServicesControl";
import Props from "UI/Common/Props";

const HomepageView: React.FunctionComponent<Props> = () => {
	const {config} = ConfigContext.useConfig();

	return (
		<div className="homepage-view h-100">
			<div className="skew d-flex flex-column">
				<div className="flex-grow-1">
					<Navbar className="main-nav" expand="md">
						<Container fluid={"xl"} className="d-flex justify-content-center mt-5">
							<Navbar.Brand href="/">
								<img
									src="/assets/images/logo.png"
									alt={config.app.name}
									style={{'height': '10rem'}}
								/>
							</Navbar.Brand>
						</Container>
					</Navbar>
					<Container fluid={"xl"}>
						<div className="text-center">
							<h1 className="mb-5">Komplexné služby v oblasti elektro</h1>
							<p>
								Spoločnosť TD elektro poskytuje komplexné riešenia v oblasti elektroinštalácií.<br/>
								Od profesionálneho vypracovania projektov cez realizáciu až po odborné skúšky
								vyhradených technických zariadení elektrických.
							</p>
						</div>
					</Container>
				</div>
				<div className="d-flex flex-grow-1 align-items-center bg-light py-5">
					<Container fluid={"xl"}>
						<ServicesControl/>
					</Container>
				</div>
			</div>
			<div className="skew d-flex justify-content-center flex-column py-5">
				<Container fluid={"xl"}>
					<ContactControl/>
				</Container>
			</div>
		</div>
	);
};

export default HomepageView;