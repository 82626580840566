const services = [
	{
		"title": "Poruchová služba",
		"image": "poruchova-sluzba-nonstop.png",
		"description": `
			<div>
				Odstraňujeme poruchy v bytoch, domoch,
				priemyselných objektoch, ale aj v komerčných objektoch do 1000V. V prípade, že nie je možné poruchu odstrániť na
				mieste, dohodneme si s objednávateľom ďalší postup na odstránení poruchy v čo najkratšom možnom čase.
				<br/><br/>
				<b>Žilina, Martin, Bytča, Čadca, Rajec, Ružomberok, Považská Bystrica.</b>
			</div>
		`
	},
	{
		"title": "Revízie",
		"image": "revizie.jpg",
		"description": `
			<div>
				Odborné prehliadky a skúšky vykonávame v zmysle vyhlášky MPSVaR 508/2009
				Z.z. v priemyselných objektoch, školách, hoteloch, bankách, rodinných
				domoch, bytoch, záhradách a pod.
			</div>
		`
	},
	{
		"title": "Projekty",
		"image": "projekty.png",
		"description": `
			<div>
				Ponúkame individuálny prístup, technickú podporu a
				poradenstvo pri vypracovaní projektovej dokumentácie pre stavebné povolenie, realizáciu stavby alebo
				projekt skutočného vyhotovenia.
				<div class="mt-3 mb-2">Typy projektov:</div>
				<ul>
					<li>Elektroinštalácie NN,</li>
					<li>Bleskozvody,</li>
					<li>Fotovoltika</li>
				</ul>
			</div>
		`
	},
	{
		"title": "Realizácie",
		"image": "realizacie.jpg",
		"description": `
			<div>
				V oblasti elektroinštalácií, bleskozvodov a systémov ochrany pred bleskom
				sa opierame o naše dlhoročné skúsenosti v obore, ktoré sú podporované pravidelnými školeniami.
			</div>
		`
	}
];

export default services;