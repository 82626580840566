import {AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse} from "axios";
import ConfigContext from "Config/ConfigContext";
import React from 'react';
import api from 'Services/Core/Api/Api';
import Props from "UI/Common/Props";

const ApiProvider: React.FunctionComponent<Props> = (props): JSX.Element => {
	const configContext = ConfigContext.useConfig();

	React.useMemo(() => {
		api.interceptors.request.use(
			(config: AxiosRequestConfig): AxiosRequestConfig => {
				config.baseURL = configContext.config.api.url;

				// const headers = { ...config.headers } as Partial<AxiosRequestHeaders>;
				//
				// config.headers = headers;

				return config;
			}
		);
		api.interceptors.response.use(
			(response: AxiosResponse): AxiosResponse => {
				return response;
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	}, [configContext]);

	return <>{props.children}</>;
};

export default ApiProvider