import React from "react";
import {Carousel} from "react-bootstrap";
import ServiceDto from "Services/TDElektro/Service/ServiceDto";
import services from "UI/Base/Homepage/Services/services";
import Props from "UI/Common/Props";
import "UI/Base/Homepage/Services/ServicesControl.scss";

const ServicesControl: React.FunctionComponent<Props> = () => {
	return (
		<div className="services-control">
			<Carousel variant="dark" indicators={false}>
				{services.map((service: ServiceDto, index: number) => (
					<Carousel.Item key={index}>
						<div className="row align-items-center justify-content-center mx-4 mx-md-0 g-5">
							<div className="col-8 col-md-3">
								<img
									className="d-block w-100 rounded-circle"
									src={"/assets/images/services/" + service.image}
									alt={service.title}
								/>
							</div>
							<div className="col-12 col-md-5">
								<h3 className="text-center text-md-start">{service.title}</h3>
								<div dangerouslySetInnerHTML={{__html: service.description}}></div>
							</div>
						</div>
					</Carousel.Item>
				))}
			</Carousel>
		</div>
	);
};

export default ServicesControl;