import React from "react";
import Props from "UI/Common/Props";

interface ErrorViewProps extends Props {
	errorCode: number;
}

const ErrorView: React.FunctionComponent<ErrorViewProps> = (props): JSX.Element => {
	const {errorCode} = props;

	if (errorCode === 403) {
		return (
			<div className="container">
				<h1>Access Denied</h1>
				<p>
					You do not have permission to View this page. Please try contact the web
					site administrator if you believe you should be able to View this page.
				</p>
				<p>
					<small>error 403</small>
				</p>
			</div>
		);
	} else if (errorCode >= 500) {
		return (
			<div className="container">
				<h1>Server Error</h1>
				<p>
					We`&apos;`re sorry! The server encountered an internal error and
					was unable to complete your request. Please try again later.
				</p>
				<p>
					<small>error 500</small>
				</p>
			</div>
		);
	} else {
		return (
			<div className="container">
				<h1>Page Not Found</h1>
				<p>
					The page you requested could not be found. It is possible that the address is
					incorrect, or that the page no longer exists. Please use a search engine to find
					what you are looking for.
				</p>
				<p>
					<small>error 404</small>
				</p>
			</div>
		);
	}
};

export default ErrorView;