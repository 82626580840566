import {yupResolver} from "@hookform/resolvers/yup";
import ConfigContext from "Config/ConfigContext";
import React from "react";
import {Alert, Button, FloatingLabel, Form} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import * as ReactHookForm from "react-hook-form";
import SendMessageRequest from "Services/TDElektro/ContactForm/SendMessageRequest";
import sendMessageService from "Services/TDElektro/ContactForm/SendMessageService";
import "UI/Base/Homepage/Contact/ContactForm.scss"
import Props from "UI/Common/Props";
import * as Yup from "yup";

interface ContactFormData {
	fullName: string;
	email: string;
	message: string;
	token: string;
}

interface ContactFormProps extends Props {
	onSuccess: () => void;
}

const ContactForm: React.FunctionComponent<ContactFormProps> = (props): JSX.Element => {
	const {onSuccess} = props;
	const {config} = ConfigContext.useConfig();
	const [contactFormError, setContactFormError] = React.useState<Error | null>(null);
	const [processing, setProcessing] = React.useState<boolean>(false);
	const captchaRef = React.useRef<ReCAPTCHA | null>(null);

	// form validation rules
	const validationSchema = Yup.object({
		fullName: Yup.string()
			.required("Tento údaj je povinný."),
		email: Yup.string()
			.required("Tento údaj je povinný.")
			.email("Neplatný tvar emailovej adresy."),
		message: Yup.string()
			.required("Tento údaj je povinný."),
		token: Yup.string()
			.required("Overenie odosielateľa je povinné.")
	});

	const {
		register,
		handleSubmit,
		formState: {errors},
		reset,
		setValue
	} = ReactHookForm.useForm<ContactFormData>({
		resolver: yupResolver(validationSchema)
	});

	// try
	const onSubmit = async (data: ContactFormData) => {
		setProcessing(true);

		const request: SendMessageRequest = {
			sender: {name: data.fullName, email: data.email},
			message: data.message,
			token: data.token
		};

		await sendMessageService(request)
			.then((): void => {
				setContactFormError(null);
				reset();
				onSuccess();
			})
			.catch((e: Error): void => {
				setContactFormError(e);
			})
			.finally((): void => {
				captchaRef.current?.reset();
				setProcessing(false);
			});
	};

	const errorMessage = (e: Error) => (
		<Alert
			variant="danger"
			onClose={() => setContactFormError(null)}
			dismissible
		>{e.message}</Alert>
	);

	return (
		<Form onSubmit={handleSubmit(onSubmit)} className="contact-form p-4 rounded-3">
			{contactFormError && errorMessage(contactFormError)}

			<FloatingLabel controlId="fullName" label="Meno a priezvisko" className="mb-3">
				<Form.Control
					{...register("fullName")}
					className={`form-control ${errors.fullName ? "is-invalid" : ""}`}
					placeholder=""
				/>
				<Form.Control.Feedback type="invalid">{errors.fullName?.message}</Form.Control.Feedback>
			</FloatingLabel>

			<FloatingLabel controlId="email" label="Email" className="mb-3">
				<Form.Control
					{...register("email")}
					className={`form-control ${errors.email ? "is-invalid" : ""}`}
					placeholder=""
					type="text"
				/>
				<Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
			</FloatingLabel>

			<FloatingLabel controlId="message" label="Správa" className="mb-3">
				<Form.Control
					{...register("message")}
					as="textarea"
					className={`form-control ${errors.message ? "is-invalid" : ""}`}
					placeholder=""
					style={{height: '10rem'}}
				/>
				<Form.Control.Feedback type="invalid">{errors.message?.message}</Form.Control.Feedback>
			</FloatingLabel>

			<div className="text-center">
				<div className="d-flex justify-content-center">
					<ReCAPTCHA
						ref={captchaRef}
						sitekey={config.google.reCAPTCHA}
						onChange={(value) => setValue("token", value || '', {shouldValidate: true})}
					/>
				</div>
				<input
					{...register("token")}
					className={`d-none ${errors.token ? "is-invalid" : ""}`}
					type="text"
				/>
				<Form.Control.Feedback type="invalid">{errors.token?.message}</Form.Control.Feedback>
			</div>

			<hr className="mt-5"/>

			<Button
				variant="outline-primary"
				type="submit"
				className="w-100"
				size="lg"
				disabled={processing}
			>Odoslať</Button>
		</Form>
	);
};

export default ContactForm;