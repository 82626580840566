import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ConfigContext from "Config/ConfigContext";
import React from "react";
import {Alert} from "react-bootstrap";
import ContactForm from "UI/Base/Homepage/Contact/ContactForm";
import Props from "UI/Common/Props";

const ContactControl: React.FunctionComponent<Props> = () => {
	const {config} = ConfigContext.useConfig();
	const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);

	const successMessage = (
		<Alert
			variant="success"
			onClose={() => setShowSuccessMessage(false)}
			dismissible
		>Vaša správa bola úspešne odoslaná.</Alert>
	);
	const onMessageSent = (): void => {
		setShowSuccessMessage(true);
	};

	return (
		<div>
			<h1 className="text-center mb-5">Kontakt</h1>
			<div className="row g-5">
				<div className="col-xl">
					<div className="text-center">
						<img
							src="/assets/images/logo.png"
							alt={config.app.name}
							style={{'height': '10rem'}}
						/>
					</div>
					<h2 className="mb-3 text-center">
						{config.app.owner.fullName}<br/>
						{config.app.owner.companyName}
					</h2>
					<div className="d-flex gap-4 justify-content-center mb-5">
						<dl className="d-flex gap-2 m-0">
							<dt className="fs-6">IČO:</dt>
							<dd>{config.app.owner.ico}</dd>
						</dl>
						<dl className="d-flex gap-2 m-0">
							<dt className="fs-6">DIČ:</dt>
							<dd>{config.app.owner.dic}</dd>
						</dl>
					</div>
					<div className="row">
						<div className="col-sm mb-5">
							<dl className="m-0 text-center">
								<dt className="d-flex flex-column gap-2 align-items-center mb-3">
									<FontAwesomeIcon icon={light("envelope")} className="fs-1"/>
									<span className="fs-6">Email</span>
								</dt>
								<dd>
									<a
										href={"mailto:" + config.app.owner.email}
										className="text-body"
									>{config.app.owner.email}</a>
								</dd>
							</dl>
						</div>
						<div className="col-sm mb-5">
							<dl className="m-0 text-center">
								<dt className="d-flex flex-column gap-2 align-items-center mb-3">
									<FontAwesomeIcon icon={light("phone")} className="fs-1"/>
									<span className="fs-6">Tel</span>
								</dt>
								<dd>
									<a
										href={"tel:" + config.app.owner.tel}
										className="text-body"
									>{config.app.owner.tel}</a>
								</dd>
							</dl>
						</div>
						<div className="col-sm">
							<dl className="m-0 text-center">
								<dt className="d-flex flex-column gap-2 align-items-center mb-3">
									<FontAwesomeIcon icon={light("map-marker-alt")} className="fs-1"/>
									<span className="fs-6">Adresa</span>
								</dt>
								<dd>
									<a
										href="https://goo.gl/maps/p7ezXu9u8u2wuZcx9"
										className="text-body"
									>{config.app.owner.address.street}</a>,
									<br/>
									{config.app.owner.address.zip} {config.app.owner.address.city},&nbsp;
									{config.app.owner.address.country}
								</dd>
							</dl>
						</div>
					</div>
				</div>
				<div className="col d-xl-none">
					<hr/>
				</div>
				<div className="col-xl">
					<h2 className="mb-3 text-center d-xl-none">Napíšte nám</h2>
					{showSuccessMessage && successMessage}
					<ContactForm onSuccess={onMessageSent}/>
				</div>
			</div>
		</div>
	);
};

export default ContactControl;