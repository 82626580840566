import axios from "axios";
import React from "react";
import Props from "UI/Common/Props";
import {Config, configUrl, defaultConfig} from "Config/Config";

interface ConfigContext {
	config: Config;
	setConfig: (newConfig: Config) => void;
}

const configContext = React.createContext<ConfigContext>({
	config: defaultConfig,
	setConfig: (newConfig: Config) => {}
});

const useConfig = (): ConfigContext => React.useContext<ConfigContext>(configContext);

const Provider: React.FunctionComponent<Props> = (props: Props): JSX.Element => {
	const [config, setConfig] = React.useState(defaultConfig);
	const [configLoadingState, setConfigLoadingState] = React.useState<"loading" | "ready" | "error">("loading");

	React.useEffect((): void => {
		axios.get(configUrl)
			.then((response): void => {
				setConfig(response.data);
				setConfigLoadingState("ready");
			})
			.catch((e): void => {
				console.error(`Have you provided the config file '${configUrl}'?`, e);
				setConfigLoadingState("error");
			});
	}, [setConfig]);

	if (configLoadingState === "loading") {
		return <>Loading...</>;
	} else if (configLoadingState === "error") {
		return <>Ops... something went wrong.</>;
	} else {
		return (
			<configContext.Provider value={{config: config, setConfig: setConfig}}>
				{props.children}
			</configContext.Provider>
		);
	}
};

export default {useConfig, Provider};