import ApiProvider from "Api/ApiProvider";
import ConfigContext from "Config/ConfigContext";
import React from "react";
import * as Router from "react-router-dom";
import BaseView from "UI/Base/BaseView";

const App: React.FunctionComponent = (): JSX.Element => {
	const {config} = ConfigContext.useConfig();

	React.useEffect(
		() => {
			document.title = config.app.name;
		}
	);

	return (
		<ConfigContext.Provider>
			<ApiProvider>
				<Router.BrowserRouter>
					<Router.Routes>
						<Router.Route path="/*" element={<BaseView/>}/>
					</Router.Routes>
				</Router.BrowserRouter>
			</ApiProvider>
		</ConfigContext.Provider>
	);
};

export default App;